import * as React from "react";
import Helmet from "react-helmet";

import {
  address,
  contact
} from "../../../data/config";
import {PropsWithChildren} from "react";

interface PropsMarkupService extends  PropsWithChildren<any>{
  name: string;
  slug: string;
  image: string;
}

const ServiceMarkupSchema: React.FC<PropsMarkupService> = (props: PropsMarkupService) => {
  const markup = `{
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  "name": "${props.name}",
  "image": "${props.image}",
  "url": "https://obtura.tv/servicio/${props.slug}",
  "email": "${contact.email}",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "${address.streetAddress}",
    "addressLocality": "${address.city}",
    "postalCode": "${address.zipCode}",
    "addressCountry": "ES"
  }
}
`;
  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
    </Helmet>
  );
};

export default ServiceMarkupSchema;
