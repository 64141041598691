import React from 'react';
import { SectionPasos } from "./style-servicios";

export const Pasos = () => {
    return (
        <SectionPasos>
            <div>
                <div className={"grid-x2"}>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--green"}>01 Preproducción</h3>
                        <p>
                        ¡Nos tenemos que conocer! Vernos las caras, charlar un rato, etc. En este primer 
                        encuentro nos tendrás que ver como unos buenos amigos a los que contar tus necesidades y tus objetivos.
                         Nosotros tomamos nota y ponemos la maquinaria en marcha para crear las propuestas necesarias 
                         (guiones, brainstorming, etc.) y hacerte la correspondiente presentación. 
                        </p>
                    </div>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--blue"}>02 Producción</h3>
                        <p>
                            Una vez con todo planificado llega nuestro momento favorito: rodar. Aquí ya nos sentimos como pez en el agua porque transformamos las ideas del primer punto en imágenes. 
                        </p>
                    </div>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--orange"}>03 Postproducción</h3>
                        <p>
                        Tras el día o los días de rodaje llega la magia de la edición y las horas de estudio. Completamos el esqueleto de la pieza mediante el montaje y la edición, y te lo vamos enseñando para que veas los avances. No te preocupes, sabemos que no se acierta a la primera: veremos contigo los cambios que necesites. 
                        </p>
                    </div>
                    <div className={"relative"}>
                        <h3 className={"title__circle title__circle--green"}>04 El día D </h3>
                        <p>
                            El día de la entrega de un trabajo es clave para nosotros. Una vez revisado el producto final, llega el día clave: la entrega al cliente. Y el material correspondiente, claro. Es un momento muy especial porque cada vídeo y cada fotografía que tratamos en Obtura es única. Y, además, toca comprobar si despierta en ti los mismos sentimientos que en nosotros.
                        </p>
                    </div>
                </div>
            </div>
        </SectionPasos>
    );
};

export default Pasos;